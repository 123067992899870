<template>
  <div>
    <div
      style="min-height: auto"
      v-if="item.motivation.show"
      v-html="htmlData(item.motivation.content)"
    ></div>
    <div style="padding: 10px 0" v-if="item.horizontal">
      <v-slide-group
        class="slide_listing"
        v-model="active_listing_tab"
        center-active
      >
        <v-slide-item
          v-for="(listingItem, index) in content.content.listing"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-btn
            :input-value="active_listing_tab === index"
            outlined
            small
            depressed
            class="mx-2"
            style="border: none; border-radius: 12px"
            :style="`background:${
              active_listing_tab === index
                ? item.styles.horizontal_active_background
                : item.styles.horizontal_background
            };color:${
              active_listing_tab === index
                ? item.styles.horizontal_active_color
                : item.styles.horizontal_color
            };`"
            @click="
              toggle;
              scrollToTitle(index);
            "
          >
            {{ strip(listingItem.title) }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
    <div v-if="item.styles.listing_vector === 'horizontal'" style="overflow:hidden;">
      <div
        style="margin-bottom: 15px; display: flex; flex-direction: column"
        v-for="(listingItem, index) in content.content.listing.filter(
          (x) => x.hide
        )"
        :key="index"
      >
        <div
          :ref="`listing${index}`"
          style="height: auto; min-height: auto; padding: 5px"
          v-html="htmlData(listingItem.title)"
        ></div>


        <swiper
            ref="mySwiper"
            :options="swiperOptions"
            @click-slide="(slideIndex) => changeListingPage(index, slideIndex, type)"
        >

          <swiper-slide 
            :id="`listing_${index}_${listingIndex}`"
            class="listing listing__item"
            v-for="(listing, listingIndex) in listingItem.content.filter(
              (x) => x.hide
            )"
            :key="listingIndex"
            :style="
              displayType(item.styles, listing) +
              `border-radius:${item.styles.borderListingRadius}px;padding:0;min-height:${listingHeight};width:${content.styles.width}px;`
            ">

            <div
              :class="`preview__inside__${index}__${listingIndex}`"
              v-if="listing.preview"
              style="z-index: -1; position: absolute; width: 100%; height: 100%"
            ></div>

            <div
              :class="`listing__item__img__${index}__${listingIndex}`"
              v-if="listing.main_img && item.styles.displayType !== 'inside'"
              style="float: left; margin-right: 5px"
              :style="`overflow:hidden;width:${
                item.styles.displayType === 'horizontal'
                  ? item.styles.listing_img_width + 'px'
                  : '100%'
              };height:${
                item.styles.listing_img_height
              }px;overflow:hidden;`"
            >
              <img
                :src="listing.main_img"
                alt=""
                :style="`height:${item.styles.listing_img_height}px;width:${
                  item.styles.displayType === 'horizontal'
                    ? item.styles.listing_img_width + 'px'
                    : '100%'
                };`"
                style="width: 100%"
              />
            </div>
            <div
              v-if="content.styles.content_position === 'center'"
              :style="`height:${content.styles.lining_height}px;width:${content.styles.lining_width}%;font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;text-align:${
                item.styles.displayType !== 'horizontal' ? 'center' : 'left'
              };padding-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };margin-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };`"
            >
              <div
                :style="`height:100%;width:100%;border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? content.styles.lining_padding+'px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };`"
              >
                <p
                  class="listing__name"
                  :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                >
                  {{ listing.name }}
                </p>
                <p
                  class="listing__text"
                  style="margin-top: 10px"
                  v-if="listing.show_descr"
                  :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                >
                  {{ listing.descr }}
                </p>
              </div>
            </div>
          </swiper-slide>


        </swiper>

      </div>

    </div>
    <div v-else>
      <draggable
          draggable=".draggable_group"
          :list="content.content.listing.filter((x) => x.hide)"
      >
        <div
            class="draggable_group"
            style="margin-bottom: 15px; display: flex; flex-direction: column"
            v-for="(listingItem, index) in content.content.listing.filter(
          (x) => x.hide
        )"
            :key="index"
        >
          <div
              :ref="`listing${index}`"
              style="height: auto; min-height: auto;margin-bottom:10px;"
              v-html="htmlData(listingItem.title)"
          ></div>
          <draggable
              style="height: auto; min-height: auto"
              draggable=".listing__item"
              :list="listingItem.content"
          >
            <div
                v-for="(listing, listingIndex) in listingItem.content.filter(
              (x) => x.hide
            )"
                :key="listingIndex">

              <div
                  v-if="content.styles.content_position === 'top' && item.styles.displayType === 'inside'"
                  style="height: 100%"
                  :style="`font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;text-align:${
                item.styles.displayType !== 'horizontal' ? 'center' : 'left'
              };padding-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };margin-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };`"
              >
                <div
                    :style="`border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? '15px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };`"
                >
                  <p
                      class="listing__name"
                      :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                  >
                    {{ listing.name }}
                  </p>
                  <p
                      class="listing__text"
                      style="margin-top: 10px"
                      v-if="listing.show_descr"
                      :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                  >
                    {{ listing.descr }}
                  </p>
                </div>
              </div>
            <div
                :id="`listing_${index}_${listingIndex}`"
                class="listing listing__item"
                style="
                position:relative;
                  display: block;
                  margin-bottom: 15px;
                  padding: 5px;
                "
                :style="
              displayType(item.styles, listing) +
              `overflow:hidden;border-radius:${item.styles.borderListingRadius}px;padding:0;min-height:${listingHeight};box-shadow:0 ${content.styles.shadow_size}px 6px ${content.styles.shadow_color};border:${content.styles.border_size}px solid ${content.styles.border_color};`
            "
                @click.prevent="changeListingPage(index, listingIndex, type)"
            >
              <div
                  :class="`preview__inside__${index}__${listingIndex}`"
                  v-if="listing.preview"
                  style="z-index: -1; position: absolute; width: 100%; height: 100%"
              ></div>
              <div
                  :class="`listing__item__img__${index}__${listingIndex}`"
                  v-if="listing.main_img && item.styles.displayType !== 'inside'"
                  style="float: left; margin-right: 5px"
                  :style="`overflow:hidden;width:${
                item.styles.displayType === 'horizontal'
                  ? item.styles.listing_img_width + 'px'
                  : '100%'
              };height:${
                item.styles.listing_img_height
              }px;`"
              >
                <img
                    :src="listing.main_img"
                    alt=""
                    :style="`height:${item.styles.listing_img_height}px;width:${
                  item.styles.displayType === 'horizontal'
                    ? item.styles.listing_img_width + 'px'
                    : '100%'
                };`"
                    style="width: 100%"
                />
              </div>
              <div
                  v-if="content.styles.content_position === 'center'&& item.styles.displayType === 'inside' || item.styles.displayType !== 'inside'"
                  :style="`height:${item.styles.displayType === 'inside'?content.styles.lining_height+'px':'100%'};width:${item.styles.displayType === 'inside'?content.styles.lining_width+'%':'100%'};font-family:${content.styles.fontFamily};
                  text-align:${
                    item.styles.displayType !== 'horizontal' ? 'center' : 'left'
                  };padding-left: ${
                    item.styles.displayType !== 'horizontal' ? '0' : '10px'
                  };margin-left: ${
                    item.styles.displayType !== 'horizontal' ? '0' : '10px'
                  };`"
              >
                <div
                    :style="checkBorderRadius()+`;display:${item.styles.displayType === 'inside'?'flex':'block'};align-items:center;justify-content:center;flex-direction:column;width:100%;height:100%;padding:${
                  item.styles.displayType === 'inside' ? content.styles.lining_padding+'px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };`"
                >
                  <p
                      class="listing__name"
                      :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};margin-bottom:0;font-size:${content.styles.nameFontSize}px;`"
                  >
                    {{ listing.name }}
                  </p>
                  <p
                      class="listing__text"
                      style="margin-top: 10px"
                      v-if="listing.show_descr"
                      :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};margin-bottom:0;font-size:${content.styles.descriptionFontSize}px;`"
                  >
                    {{ listing.descr }}
                  </p>
                </div>
              </div>
            </div>
              <div
                  v-if="content.styles.content_position === 'bottom'&& item.styles.displayType === 'inside'"
                  style="height: 100%"
                  :style="`font-family:${content.styles.fontFamily};display:${
                item.styles.displayType === 'inside' ? 'flex' : 'block'
              };justify-content:center;align-items:center;text-align:${
                item.styles.displayType !== 'horizontal' ? 'center' : 'left'
              };padding-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };margin-left: ${
                item.styles.displayType !== 'horizontal' ? '0' : '10px'
              };`"
              >
                <div
                    :style="`border-radius:${
                  item.styles.borderListingRadius
                }px;padding:${
                  item.styles.displayType === 'inside' ? '15px' : '0'
                };background:${
                  item.styles.displayType === 'inside'
                    ? content.styles.lining_color
                    : 'transparent'
                };`"
                >
                  <p
                      class="listing__name"
                      :style="`color:${listing.name_color};font-family:${content.styles.fontFamily};font-size:${content.styles.nameFontSize}px;`"
                  >
                    {{ listing.name }}
                  </p>
                  <p
                      class="listing__text"
                      style="margin:0;"
                      v-if="listing.show_descr"
                      :style="`color:${listing.descr_color};font-family:${content.styles.fontFamilyDescription};font-size:${content.styles.descriptionFontSize}px;`"
                  >
                    {{ listing.descr }}
                  </p>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import draggable from "vuedraggable";
import 'swiper/swiper-bundle.css'
import "swiper/modules/pagination/pagination.min.css"
import SwiperCore, {
  Pagination
} from 'swiper';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
SwiperCore.use([Pagination]);
export default {
  name: "typeListingListPreview",
  props: ["item", "content", "type"],
  components: {
    draggable,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      datenow: "",
      active_listing_tab: 0,
      swiperOptions:{
        slidesPerView: 'auto',
        spaceBetween: 15,
      },
    };
  },
  methods: {
    checkBorderRadius(){
      if(+this.content.styles.lining_width === 100){
        if(this.content.styles.position === 'flex-start flex-start' || this.content.styles.position === 'flex-end flex-start' || this.content.styles.position === 'center flex-start'){
          return `border-top-right-radius:${this.item.styles.borderListingRadius}px;border-top-left-radius:${this.item.styles.borderListingRadius}px;`
        }
        if(this.content.styles.position === 'flex-start flex-end' || this.content.styles.position === 'flex-end flex-end' || this.content.styles.position === 'center flex-end'){
          return `border-bottom-right-radius:${this.item.styles.borderListingRadius}px;border-bottom-left-radius:${this.item.styles.borderListingRadius}px;`
        }
      }else{
        return `border-radius:${
            this.item.styles.borderListingRadius
        }px;`
      }
    },
    alignItem(position) {
      if (position === "center") {
        return "center";
      } else if (position === "top") {
        return "flex-start";
      } else if (position === "bottom") {
        return "flex-end";
      }
    },
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    strip(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText;
    },
    changeListingPage(index, listingIndex, type) {
      if (type === "listing") {
        this.$store.commit("setListingPage", {
          listingGroup: index,
          page: this.page,
          index: listingIndex,
          subListing: null,
        });
      }
      if (type === "sublisting") {
        this.$store.commit("setSubListingPage", {
          listingGroup: index,
          page: this.page,
          index: listingIndex,
          subListing: listingIndex,
        });
      }
    },
    displayType(item, listing) {
      if (item.displayType === "horizontal") {
        return "flex-direction:row;align-items:normal;background-color:#fff;";
      } else if (item.displayType === "inside") {
        let bg = "";
        if (listing.preview) {
          bg = "transparent";
        } else {
          bg = `url(${listing.main_img}) no-repeat center / 100%`;
        }
        return `background:${bg};
                background-size:100%;
                overflow:hidden;
                text-align:center;
                height:200px;
                border-radius:10px;
                background-position: center center;
                background-repeat:no-repeat;
                display:flex;
                background-color:#fff;
                align-items:${this.content.styles.position.split(' ')[1]};
                justify-content:${this.content.styles.position.split(' ')[0]};`
      } else {
        return "flex-direction:column;background-color:#fff;";
      }
    },
    scrollToTitle(index) {
      this.active_listing_tab = index;
      let preview = document.getElementsByClassName("canvas");
      if (index === 0) {
        preview[0].scrollTop = 0;
      } else {
        preview[0].scrollTop = this.$refs[`listing${index}`][0].offsetTop - 50;
      }
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState(["page"]),
    dateToday: function () {
      return moment().format("DD.MM.YYYY");
    },
    listingHeight: function () {
      if (this.item.styles.displayType === "inside") {
        return `${this.content.styles.height}px`;
      } else if (this.item.styles.displayType === "horizontal") {
        return `${+this.item.styles.listing_img_height + 10}px`;
      } else {
          return `${this.content.styles.height}px`;
      }
    },
  },
  mounted() {
    this.timeNow();
  },
};
</script>

<style lang="scss" scoped></style>
